import { useQuery } from '@apollo/client';
import { config as FAConfig } from '@fortawesome/fontawesome-svg-core';
import { Loader, ThemeProvider } from '@gasbuddy/react-components';
import { useAnalytics } from '@gasbuddy/react-hooks';
import loadable from '@loadable/component';
import gql from 'graphql-tag';
import { useEffect, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { isWindowAvailable } from '../../../lib/utils';
import ReactCriteo from '../../../lib/utils/criteo';
import ReactGTM from '../../../lib/utils/gtm';
import hasDeletedAccount from '../../../lib/utils/hasDeletedAccount';
import logEvent from '../../../lib/utils/logEvent';
import FeaturesContext from '../../context/features';
import GlobalContext from '../../context/global';
import { Provider as PageProvider } from '../../context/page';
import ErrorBoundary from '../ErrorBoundary';
import PageLayout from '../PageLayout/PageLayout';
import PrivateRoute from '../PrivateRoute';

FAConfig.autoAddCss = false;

const LOCALYTICS_CUSTOM_DIMENSIONS = {
  USER_ID: 14,
  USER_NAME: 15,
};

const loadableOpts = {
  fallback: (
    <Loader size="lg">
      Loading page...
    </Loader>
  ),
};

export const GET_PAGES = gql`
  query GetPages{
    pages {
      ... on CMSAboutUsPage {
        url
      }
      ... on CMSLandingPage {
        url
      }
    }
  }
`;

export const FETCH_GLOBAL_CONTEXT = gql`
  query FetchGlobalContext{
    config {
      consumerHost
      enrollHost
      env
      googleAppId
      googleMapsKey
      gtm {
        id
      }
      identityHost
      imagesHost
      isMobile
      localyticsKey
      mapboxMapKey
      payHost
      publicPath
      recaptchaSiteKey
      trackerHost
    }
    features {
      n
      v
      p
    }
    profile {
      accountId
      allTimePoints
      canSetMemberName
      consecutiveDays
      deletionStatus {
        endDate
        status
      }
      email
      emailHash
      favoriteStationsCount
      gBAge
      isLoggedIn
      isPayUser
      joinDateText
      localSite
      memberName
      overallRank
      profileImageUrl
      requestedDNS
      userVehiclesQuickInfo {
        featuredVehicle {
          fuelLogCount
          guid
          make
          model
          nickname
          year
        }
        vehicleCount
      }
    }
    geoIpInfo {
      locality
      state
      zip
      country
    }
    mailingAddress {
      country
      firstName
      lastName
      line1
      line2
      locality
      postalCode
      region
    }
  }
`;

export default function Router() {
  const { data, loading } = useQuery(GET_PAGES);
  const { data: globalContextData, loading: isLoadingContext } = useQuery(FETCH_GLOBAL_CONTEXT);
  const cmsUrls = data?.pages.map(({ url }) => url) || [];
  const { criteo, gtm, localyticsKey } = globalContextData?.config || {};
  const { accountId, deletionStatus, memberName } = globalContextData?.profile || {};

  useEffect(() => {
    if (isWindowAvailable()) {
      if (criteo) {
        ReactCriteo.initialize(criteo);
      }

      if (gtm) {
        ReactGTM.initialize(gtm, accountId);
      }
    }
  }, [criteo, gtm, accountId]);

  // Make sure we reset the username modal flag in local storage if we ever have a logged out user
  if (!memberName && typeof localStorage !== 'undefined') {
    if (localStorage.getItem('lastShownMemberNameModal') !== 'none') {
      localStorage.setItem('lastShownMemberNameModal', 'none');
    }
  }

  // TODO: Update analytics hook to support SSR
  const analyticsConfig = isWindowAvailable() && {
    localytics: {
      domain: 'gasbuddy.com',
      key: localyticsKey,
    },
  };

  const analytics = useAnalytics(analyticsConfig);

  const globalContextValue = useMemo(() => ({
    ...globalContextData,
    isLoadingContext,
    analytics,
  }), [analytics, globalContextData, isLoadingContext]);

  // In case a user with a deleted account somehow manages to navigate to a page, redirect them to logout
  if (hasDeletedAccount(deletionStatus)) {
    return (
      <Redirect to="/logout" />
    );
  }

  // Don't allow a landing page to return a 404 on initial render
  if (loading) {
    return null;
  }

  if (analytics) {
    analytics.on('event', (eventName, eventAttributes) => {
      logEvent(`Localytics:${eventName}`, eventAttributes);
    });

    if (accountId) {
      analytics.setCustomDimension(LOCALYTICS_CUSTOM_DIMENSIONS.USER_ID, accountId);
    }

    if (memberName) {
      analytics.identifyUser({
        id: memberName,
      });
      analytics.setCustomDimension(LOCALYTICS_CUSTOM_DIMENSIONS.USER_NAME, memberName);
    }
  }

  return (
    <ThemeProvider>
      <FeaturesContext.Provider value={globalContextData?.features}>
        <GlobalContext.Provider value={globalContextValue}>
          <PageProvider>
            <ErrorBoundary>
              <Switch>
                <Route exact path="/account/confirm" component={loadable(() => import('../AccountAction'))} />
                {cmsUrls.map(cmsUrl => (
                  <Route key={cmsUrl} component={loadable(() => import('../CMSPage'))} exact path={`/${cmsUrl}`} />
                ))}
                <Route>
                  <PageLayout>
                    <Switch>
                      <Route exact path="/" component={loadable(() => import('../HomePage'), loadableOpts)} />
                      <Route exact path="/about/timeline" component={loadable(() => import('../TimelinePage'), loadableOpts)} />
                      <PrivateRoute exact path="/account/favorite-lists" component={loadable(() => import('../FavoriteStationsListsPage'), loadableOpts)} />
                      <PrivateRoute exact path="/account/favorite-lists/:favoriteListId" component={loadable(() => import('../FavoritesListPage'), loadableOpts)} />
                      <PrivateRoute exact path="/account/fuel-log-book" component={loadable(() => import('../RedirectToFuelLogBook'), loadableOpts)} />
                      <PrivateRoute exact path="/account/preferences" component={loadable(() => import('../UserEmailPreferencesPage'), loadableOpts)} />
                      <Route exact path="/account/profile" component={loadable(() => import('../ProfilePage'), loadableOpts)} />
                      <Route exact path="/account/savings" component={loadable(() => import('../PaySavingsPage'), loadableOpts)} />
                      <PrivateRoute exact path="/account/vehicles" component={loadable(() => import('../MyVehiclesPage'), loadableOpts)} />
                      <PrivateRoute exact path="/account/vehicles/:guid" component={loadable(() => import('../MyVehicleProfilePage'), loadableOpts)} />
                      <PrivateRoute exact path="/account/vehicles/:guid/fuel-log-book" component={loadable(() => import('../FuelLogBookPage'), loadableOpts)} />
                      <PrivateRoute exact path="/account/vehicles/:guid/fuel-log-book/entry/:fuelLogGuid?" component={loadable(() => import('../FuelLogEntryPage'), loadableOpts)} />
                      <Route exact path="/app" component={loadable(() => import('../AppPage'), loadableOpts)} />
                      <Route exact path="/brands/:brandKey" component={loadable(() => import('../BrandPage'), loadableOpts)} />
                      <Route exact path="/contest/gas-card-give-away" component={loadable(() => import('../EnterToWinPage'), loadableOpts)} />
                      <Route path="/disclaimer/:countryCode?" component={loadable(() => import('../LegalPage'), loadableOpts)} />
                      <Route exact path="/do-not-sell-my-personal-information" component={loadable(() => import('../DoNotSellMyPI'), loadableOpts)} />
                      <Route exact path="/emailpreferences" component={loadable(() => import('../VisitorPreferencesPage'), loadableOpts)} />
                      <Route exact path="/gasback" component={loadable(() => import('../GasbackLanding'), loadableOpts)} />
                      <Route exact path="/gasprices" component={loadable(() => import('../RegionSelectPage'), loadableOpts)} />
                      <Route exact path="/gasprices/:region/:area?/:countyId?" component={loadable(() => import('../RegionalGasPrices'), loadableOpts)} />
                      <Route exact path="/home" component={loadable(() => import('../SearchResultsPage'), loadableOpts)} />
                      <Route exact path="/leaderboards" component={loadable(() => import('../LeaderboardsPage'), loadableOpts)} />
                      <Route exact path="/newsroom" component={loadable(() => import('../NewsroomPage'), loadableOpts)} />
                      <Route exact path="/newsroom/inthenews" component={loadable(() => import('../NewsArticlesPage'), loadableOpts)} />
                      <Route exact path="/newsroom/pressrelease/:legacyId" component={loadable(() => import('../PressReleaseLookup'), loadableOpts)} />
                      <Route exact path="/newsroom/pressrelease/:year/:month/:day/:identifier" component={loadable(() => import('../PressReleasePage'), loadableOpts)} />
                      <Route exact path="/newsroom/pressreleases" component={loadable(() => import('../PressReleasesPage'), loadableOpts)} />
                      <Route exact path="/pay/plus" component={loadable(() => import('../PayPlusPage'), loadableOpts)} />
                      <Route path="/privacy/:countryCode?" component={loadable(() => import('../LegalPage'), loadableOpts)} />
                      <Route path="/regionstats" component={loadable(() => import('../RegionPriceStatisticsPage'), loadableOpts)} />
                      <Route exact from="/station/:brandName/:regionCode/:regionDescription/:stationId([0-9]+)" to="/station/:stationId" />
                      <Route exact path="/station/:stationId([0-9]+)" component={loadable(() => import('../Station'), loadableOpts)} />
                      <Route path="/terms/:countryCode?" component={loadable(() => import('../LegalPage'), loadableOpts)} />
                      <Route path="/tripcostcalculator" component={loadable(() => import('../TripCostCalculatorPage'), loadableOpts)} />
                      <Route exact path="/vehicles/recalls/results" component={loadable(() => import('../VehicleRecallSearchResultsPage'), loadableOpts)} />
                      <Route exact path="/vehicles/recalls/search" component={loadable(() => import('../VehicleRecallSearchPage'), loadableOpts)} />
                      <Route component={loadable(() => import('../Unknown'), loadableOpts)} />
                    </Switch>
                  </PageLayout>
                </Route>
              </Switch>
            </ErrorBoundary>
          </PageProvider>
        </GlobalContext.Provider>
      </FeaturesContext.Provider>
    </ThemeProvider>
  );
}
