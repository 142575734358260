import AD_SLOTS from './ads';

export { AD_SLOTS };

/**
 * Analytics events for Google/Localytics
 */
export { default as ANALYTICS_EVENTS } from './analyticsEvents';

/**
 * List of brands and corresponding data to show on /brands/{brandName}
 */
export { default as BRANDS } from './brands';

/**
 * Banner Ad DFP Specifications
 */
export const BANNER_ADS = [
  {
    id: AD_SLOTS[1].slotID,
    path: AD_SLOTS[1].slotName,
    sizes: AD_SLOTS[1].sizes,
    sizeMapping: AD_SLOTS[1].sizeMapping,
  },
  {
    id: AD_SLOTS[0].slotID,
    path: AD_SLOTS[0].slotName,
    sizes: AD_SLOTS[0].sizes,
    sizeMapping: AD_SLOTS[0].sizeMapping,
  },
];

/**
 * DEFAULT LOGO for brands
 */

export const DEFAULT_LOGO_URL = 'https://images.gasbuddy.io/b/0.png';

/**
 * DEFAULT LOGO for member profile images
 */
export const DEFAULT_MEMBER_PROFILE_IMAGE = 'https://images.gasbuddy.com/images/winners/FetchImage.jpg';

/**
 * Standard list of Country codes
 */
export const COUNTRY_CODES = {
  USA: 'US',
  CANADA: 'CA',
};

/**
 * Standard list of country currency codes
 */
export const CURRENCY_CODES = {
  USA: 'USD',
  CANADA: 'CAD',
  AUSTRALIA: 'AUD',
};

/**
 * The country code for Australia.
 * This is not needed once australian deprecation content can be removed.
 */
export const AUSTRALIA_COUNTRY_CODE = 'AU';

/**
 * Countries that utilize metric, eg, Cents per Litre, Kilometers, et al
 */
export const COUNTRIES_WITH_METRIC = [COUNTRY_CODES.CANADA];

/**
 * Countries where Pay With GasBuddy is available and/or advertised to
 */
export const COUNTRIES_WITH_PAY = [COUNTRY_CODES.USA];

/**
 * GBApi Constant for Imperial units
 */
export const DISTANCE_IMPERIAL = 1;

/**
 * GBApi Constant for Metric units
 */
export const DISTANCE_METRIC = 0;

/**
 * Conversion rate of how many kilometers are in one mile
 */
export const KM_TO_MILES = 1.609344;

/**
 * When GeoIP/LatLng coordinates are not available, use these pre-geocoded locations instead
 */
export const DEFAULT_LOCATIONS = {
  boston: {
    range: [1276316416, 1276316671],
    country: COUNTRY_CODES.USA,
    region: 'MA',
    city: 'Boston',
    ll: [42.3731, -71.0162],
    metro: 506,
    zip: 2128,
    isDefault: true,
  },
  losAngeles: {
    range: [2509162496, 2509163519],
    country: COUNTRY_CODES.USA,
    region: 'CA',
    city: 'Los Angeles',
    ll: [34.0318, -118.4252],
    metro: 803,
    zip: 90016,
    isDefault: true,
  },
};

/**
 * Where IP address of the client (for geoip) isn't available, use these known IP addresses
 */
export const DEFAULT_IPS = {
  boston: '76.19.11.68',
  regina: '32.60.64.10',
};

/**
 * Mapping of fuel ID to poi-serv values
 */
export const FUEL_ID_TO_POI_VALUE = {
  1: 'regular_gas',
  2: 'midgrade_gas',
  3: 'premium_gas',
  4: 'diesel',
  5: 'e85',
  12: 'e15',
};

/**
 * Mapping of fuel poi-serv values to formal fuel type names
 */
export const FUEL_POI_VALUE_TO_NAME = {
  regular_gas: 'Regular',
  midgrade_gas: 'Midgrade',
  premium_gas: 'Premium',
  diesel: 'Diesel',
  e85: 'E85',
  e15: 'UNL88',
};

/**
 * Mapping of formal fuel type names
 */
export const FUEL_TYPES = {
  REGULAR: 'Regular',
  MIDGRADE: 'Midgrade',
  PREMIUM: 'Premium',
  DIESEL: 'Diesel',
  E85: 'E85',
  E15: 'UNL88',
};

/**
 * Standard list of fuels, their descriptions, and GBApi Constants
 */
export const FUEL_OPTIONS = [
  { key: 'regular', label: FUEL_TYPES.REGULAR, value: 1 },
  { key: 'midgrade', label: FUEL_TYPES.MIDGRADE, value: 2 },
  { key: 'premium', label: FUEL_TYPES.PREMIUM, value: 3 },
  { key: 'diesel', label: FUEL_TYPES.DIESEL, value: 4 },
  { key: 'e85', label: FUEL_TYPES.E85, value: 5 },
  { key: 'e15', label: FUEL_TYPES.E15, value: 12 },
];

/**
 * List of available payment options for station search filtering
 */
export const PAYMENT_OPTIONS = [
  { key: 'all', label: 'Cash or Credit', value: 'all' },
  { key: 'credit', label: 'Credit Only', value: 'credit' },
];

/**
 * List of hours for searching for recent prices
 */
export const MAX_PRICE_AGE_OPTIONS = [
  { key: '0', label: 'No Limit', value: 0 },
  { key: '4', label: '4 hours', value: 4 },
  { key: '8', label: '8 hours', value: 8 },
  { key: '12', label: '12 hours', value: 12 },
  { key: '24', label: '24 hours', value: 24 },
  { key: '36', label: '36 hours', value: 36 },
  { key: '48', label: '48 hours', value: 48 },
];

/**
 * Number of ms to wait for the NewsFeed RSS parser to do its thing,
 * before returning an empty response and timeout.
 */
export const NEWSFEED_MAX_RESPONSE_TIME_IN_MS = 5000;

/**
 * When a price is too old to be considered for display, show this string in its place.
 */
export const OUTDATED_PRICE_DISPLAY = '- - -';

export const CURRENT_TIME_OPTION_DISPLAY = 'Current Time';

export const DATAFEED_MEMBER = {
  iconPath: 'https://static.gasbuddy.com/web/consumer/datafeed-icon.svg',
  linkUrl: 'https://www.gasbuddy.com/member/datafeed',
};

export const OWNER_MEMBER = {
  iconPath: 'https://static.gasbuddy.com/web/consumer/owner-icon.svg',
  linkUrl: 'https://www.gasbuddy.com/member/owner',
};

export const REGULAR_MEMBER = memberName => ({
  iconPath: 'https://static.gasbuddy.com/web/consumer/user-icon.svg',
  linkUrl: `https://www.gasbuddy.com/member/${encodeURIComponent((memberName || 'visitor').toLowerCase())}`,
});

/**
 * GBApi constant corresponding with the Credit Card price of a fuel
 */
export const PRICE_TYPE_CREDIT = 1;

/**
 * Standard list of REGEX patterns that are honored site-wide
 */
export const REGEX = {
  // eslint-disable-next-line no-useless-escape, max-len
  EMAIL: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  POSTALCODE: {
    USA: /^[0-9]{5}(?:-[0-9]{4})?$/,
    CAN: /^[A-Za-z]\d[A-Za-z]\s?\d[A-Z-a-z]\d$/,
    AUS: /^[0-9]{4}$/,
  },
  PRICE: {
    GENERAL: /^[1-9]+\d*(\.?\d{0,2})?$/,
    STATION: {
      PER_GALLON: /^[1-9](\.\d{0,2})?$/,
      PER_LITRE: /^[1-9](\d{1,2})?(\.\d{0,1})?$/,
    },
  },
  USER_NAME: /^[a-zA-Z0-9_-]{5,15}$/,
};

/**
 * List of Local Sites we feature; displayed in Search Results for related regions
 */
export const SITES = [
  'Alberta, AB',
  'Calgary, AB',
  'Edmonton, AB',
  'Alaska, AK',
  'Anchorage, AK',
  'Alabama, AL',
  'Birmingham, AL',
  'Huntsville, AL',
  'Mobile, AL',
  'Montgomery, AL',
  'Arkansas, AR',
  'Little Rock, AR',
  'Arizona, AZ',
  'Phoenix, AZ',
  'Tucson, AZ',
  'Abbotsford, BC',
  'British Columbia, BC',
  'Vancouver, BC',
  'Victoria, BC',
  'Bakersfield, CA',
  'California, CA',
  'Chico, CA',
  'Fresno, CA',
  'Los Angeles, CA',
  'Modesto, CA',
  'Oakland, CA',
  'Orange County, CA',
  'Riverside, CA',
  'Sacramento, CA',
  'Salinas, CA',
  'San Bernardino, CA',
  'San Diego, CA',
  'San Francisco, CA',
  'San Jose, CA',
  'Santa Barbara, CA',
  'Stockton, CA',
  'Ventura, CA',
  'Colorado, CO',
  'Colorado Springs, CO',
  'Denver, CO',
  'Fort Collins, CO',
  'Bridgeport, CT',
  'Connecticut, CT',
  'Hartford, CT',
  'New Haven, CT',
  'Waterbury, CT',
  'Washington DC, DC',
  'Delaware, DE',
  'Wilmington, DE',
  'Cape Coral, FL',
  'Florida, FL',
  'Gainesville, FL',
  'Jacksonville, FL',
  'Miami, FL',
  'Naples, FL',
  'Ocala, FL',
  'Orlando, FL',
  'Pensacola, FL',
  'Sarasota, FL',
  'Tallahassee, FL',
  'Tampa, FL',
  'Atlanta, GA',
  'Augusta, GA',
  'Georgia, GA',
  'Macon, GA',
  'Savannah, GA',
  'Hawaii, HI',
  'Honolulu, HI',
  'Des Moines, IA',
  'Iowa, IA',
  'Quad Cities, IA',
  'Boise, ID',
  'Idaho, ID',
  'Champaign, IL',
  'Chicago, IL',
  'Illinois, IL',
  'Peoria, IL',
  'Rockford, IL',
  'Evansville, IN',
  'Fort Wayne, IN',
  'Gary, IN',
  'Indiana, IN',
  'Indianapolis, IN',
  'South Bend, IN',
  'Kansas, KS',
  'Topeka, KS',
  'Wichita, KS',
  'Kentucky, KY',
  'Lexington, KY',
  'Louisville, KY',
  'Baton Rouge, LA',
  'Louisiana, LA',
  'New Orleans, LA',
  'Shreveport, LA',
  'Boston, MA',
  'Massachusetts, MA',
  'Springfield, MA',
  'Worcester, MA',
  'Manitoba, MB',
  'Winnipeg, MB',
  'Baltimore, MD',
  'Hagerstown, MD',
  'Maryland, MD',
  'Maine, ME',
  'Ann Arbor, MI',
  'Detroit, MI',
  'Flint, MI',
  'Grand Rapids, MI',
  'Kalamazoo, MI',
  'Lansing, MI',
  'Michigan, MI',
  'Minnesota, MN',
  'Twin Cities, MN',
  'Kansas City, MO',
  'Missouri, MO',
  'St. Louis, MO',
  'Jackson, MS',
  'Mississippi, MS',
  'Billings, MT',
  'Montana, MT',
  'New Brunswick, NB',
  'Asheville, NC',
  'Charlotte, NC',
  'Durham, NC',
  'Fayetteville, NC',
  'Greensboro, NC',
  'North Carolina, NC',
  'Raleigh, NC',
  'Winston-Salem, NC',
  'Fargo, ND',
  'North Dakota, ND',
  'Lincoln, NE',
  'Nebraska, NE',
  'Omaha, NE',
  'Newfoundland, NF',
  'New Hampshire, NH',
  'New Jersey, NJ',
  'Toms River, NJ',
  'Trenton, NJ',
  'Albuquerque, NM',
  'New Mexico, NM',
  'Santa Fe, NM',
  'Halifax, NS',
  'Nova Scotia, NS',
  'Northwest Territories, NT',
  'Nevada, NV',
  'Las Vegas, NV',
  'Albany, NY',
  'Buffalo, NY',
  'Long Island, NY',
  'New York City, NY',
  'New York, NY',
  'Rochester, NY',
  'Syracuse, NY',
  'Akron, OH',
  'Cincinnati, OH',
  'Cleveland, OH',
  'Columbus, OH',
  'Dayton, OH',
  'Ohio, OH',
  'Toledo, OH',
  'Oklahoma, OK',
  'Oklahoma City, OK',
  'Tulsa, OK',
  'Barrie, ON',
  'Hamilton, ON',
  'Kitchener-Waterloo, ON',
  'London, ON',
  'Ontario, ON',
  'Ottawa, ON',
  'Toronto, ON',
  'Eugene, OR',
  'Medford, OR',
  'Oregon, OR',
  'Portland, OR',
  'Salem, OR',
  'Allentown, PA',
  'Erie, PA',
  'Harrisburg, PA',
  'Lancaster, PA',
  'Pennsylvania, PA',
  'Philadelphia, PA',
  'Pittsburgh, PA',
  'Reading, PA',
  'Scranton, PA',
  'York, PA',
  'PEI, PE',
  'Montreal, QC',
  'Quebec, QC',
  'Quebec City, QC',
  'Providence, RI',
  'Rhode Island, RI',
  'Columbia, SC',
  'Greenville, SC',
  'Myrtle Beach, SC',
  'South Carolina, SC',
  'Spartanburg, SC',
  'Sioux Falls, SD',
  'South Dakota, SD',
  'Regina, SK',
  'Saskatchewan, SK',
  'Saskatoon, SK',
  'Chattanooga, TN',
  'Knoxville, TN',
  'Memphis, TN',
  'Nashville, TN',
  'Tennessee, TN',
  'Amarillo, TX',
  'Austin, TX',
  'College Station, TX',
  'Corpus Christi, TX',
  'Dallas, TX',
  'El Paso, TX',
  'Fort Worth, TX',
  'Houston, TX',
  'Laredo, TX',
  'Lubbock, TX',
  'Midland Odessa, TX',
  'Rio Grande Valley, TX',
  'San Antonio, TX',
  'Texas, TX',
  'Waco, TX',
  'Ogden, UT',
  'Provo, UT',
  'Salt Lake City, UT',
  'Utah, UT',
  'Richmond, VA',
  'Roanoke, VA',
  'Virginia, VA',
  'Virginia Beach, VA',
  'Burlington, VT',
  'Vermont, VT',
  'Seattle, WA',
  'Spokane, WA',
  'Tacoma, WA',
  'Washington, WA',
  'Yakima, WA',
  'Appleton, WI',
  'Green Bay, WI',
  'Madison, WI',
  'Milwaukee, WI',
  'Wisconsin, WI',
  'Charleston, WV',
  'West Virginia, WV',
  'Wyoming, WY',
  'Yukon, YT',
];

/**
 * Number of station search results to return in a cursor
 */
export const STATION_LIST_LIMIT = 10;

/**
 * Number of station seasrch results to return in a cursor for max price age filters
 */
export const STATION_LIST_MAX_PRICE_AGE_LIMIT = 25;

/**
 * GBApi constants responding to Trend direction
 */
export const TREND_VALUES = {
  Up: 1,
  Stable: 0,
  Down: -1,
};

/**
 * Standard list of supported Countries
 */
export const COUNTRIES = {
  US: 'United States of America',
  CA: 'Canada',
};

/**
 * Standard list of US States
 */
export const STATES_US = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

/**
 * Standard list of Canadian Provinces
 */
export const PROVINCES = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon Territory',
};

/**
 * All required fields in any address form
 */
export const REQUIRED_ADDRESS_FIELDS = [
  'firstName',
  'lastName',
  'line1',
  'locality',
  'region',
  'postalCode',
  'country',
];

export const DEFAULT_STATION_LOGO = 'https://images.gasbuddy.io/b/0.png';

export const DEFAULT_RECENT_WINNERS_COUNT = 1;

export const DEFAULT_VEHICLE_IMAGE = 'https://static.gasbuddy.com/web/consumer/vehicles/vehicle.svg';

export const INITIAL_RECALL_RESULTS_TO_SHOW = 5;

export const DEFAULT_FAVORITE_LIST_NAME = 'Favorites';

export const KEYNAMES = {
  ENTER: 'Enter',
  SPACE: 'Space',
  BACKSPACE: 'Backspace',
  LEFT_ARROW: 'ArrowLeft',
  UP_ARROW: 'ArrowUp',
  RIGHT_ARROW: 'ArrowRight',
  DOWN_ARROW: 'ArrowDown',
  TAB: 'Tab',
};

export const DO_NOT_SELL_MY_PI_FORM_URL = 'https://help.gasbuddy.com/hc/en-us/requests/new?ticket_form_id=360002353354';

export const CUSTOM_VEHICLE_MAKE_ID = 999999;

export const CUSTOM_VEHICLE_MODEL_ID = 999999;

export const CUSTOM_VEHICLE_TRIM_ID = 999999;

export const LOGIN_STATUS = {
  LoggedOut: 0,
  InProgress: 1,
  Failed: 2,
  LoggedIn: 3,
  AccountNotFound: 4,
  EmailMismatch: 5,
  AccountNotLinked: 6,
};

export const HIDE_BETA_PANEL = 'hideBetaMessaging';
