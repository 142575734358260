import { createContext } from 'react';
import { noop } from '../../lib/utils';
import { DEFAULT_LOCATIONS } from '../constants';

export const DefaultGlobalContextValue = {
  analytics: {
    identifyUser: noop,
    tagEvent: noop,
    tagPageView: noop,
    isInitialized: false,
  },
  config: {
    criteo: '',
    consumerHost: 'www.gasbuddy.com',
    enrollHost: 'enroll.gasbuddy.com',
    env: 'development',
    googleAppId: '',
    googleMapsKey: '',
    gtm: null,
    identityHost: 'iam.gasbuddy.com',
    imagesHost: 'images.gasbuddy.io',
    isMobile: false,
    localyticsKey: '',
    mapboxMapKey: '',
    payHost: 'pay.gasbuddy.com',
    publicPath: '/',
    recaptchaSiteKey: '',
    trackerHost: 'tracker.gasbuddy.com',
  },
  geoIpInfo: {
    locality: DEFAULT_LOCATIONS.boston.city,
    state: DEFAULT_LOCATIONS.boston.region,
    zip: DEFAULT_LOCATIONS.boston.zip,
    country: DEFAULT_LOCATIONS.boston.country,
  },
  profile: {
    accountId: null,
    canSetMemberName: false,
    deletionStatus: {},
    isLoggedIn: false,
    isPayUser: false,
    memberName: null,
    profileImageUrl: null,
    userVehiclesQuickInfo: {
      featuredVehicle: null,
      vehicleCount: 0,
    },
  },
  mailingAddress: {
    country: null,
    firstName: null,
    lastName: null,
    line1: null,
    line2: null,
    locality: null,
    postalCode: null,
    region: null,
  },
};

const GlobalContext = createContext(DefaultGlobalContextValue);

export default GlobalContext;
