const Dimensions = {
  Mobile: [0, 0],
  Tablet: [768, 0],
  Desktop: [992, 0],
};

export const DFP_NETWORK_ID = '1025479';

export default [
  { // 0
    slotName: 'GasBuddy_Newmobieweb_320x50',
    slotID: 'div-gpt-ad-1430835791415-0',
    sizes: [[320, 50]],
    // Only show ad on mobile devices, order matters!
    // See https://support.google.com/admanager/answer/3423562?hl=en
    sizeMapping: [
      [Dimensions.Tablet, []],
      [Dimensions.Mobile, [
        [320, 50],
      ]],
    ],
  },
  { // 1
    slotName: 'GasBuddy_Content_Top_728x90',
    slotID: 'div-gpt-ad-1428529431626-2',
    sizes: [[728, 90], [970, 250]],
    // Only show ad on tablet & desktop devices
    sizeMapping: [
      [Dimensions.Tablet, [
        [728, 90],
        [970, 250],
      ]],
      [Dimensions.Mobile, []],
    ],
  },
  { // 2
    slotName: 'GasBuddy_Content1_300x250',
    slotID: 'div-gpt-ad-1428529431626-3',
    sizes: [[300, 250]],
    // Only show ad on tablet & desktop devices
    sizeMapping: [
      [Dimensions.Tablet, [
        [300, 250],
      ]],
      [Dimensions.Mobile, []],
    ],
  },
  { // 3
    slotName: 'GasBuddy_Content2_300x250',
    slotID: 'div-gpt-ad-1428529431626-4',
    sizes: [[300, 250]],
    // Only show ad on tablet & desktop devices
    sizeMapping: [
      [Dimensions.Tablet, [
        [300, 250],
      ]],
      [Dimensions.Mobile, []],
    ],
  },
  { // 4
    slotName: 'GasBuddy_Content3_300x250',
    slotID: 'div-gpt-ad-1428529431626-5',
    sizes: [[300, 250]],
    // Only show ad on tablet & desktop devices
    sizeMapping: [
      [Dimensions.Tablet, [
        [300, 250],
      ]],
      [Dimensions.Mobile, []],
    ],
  },
  { // 5
    slotName: 'GasBuddy_Main_300x250',
    slotID: 'div-gpt-ad-1428529431626-6',
    sizes: [[300, 250]],
  },
  { // 6
    slotName: 'GasBuddy_Main2_300x250',
    slotID: 'div-gpt-ad-1428529431626-7',
    sizes: [[300, 250]],
  },
  { // 7
    slotName: 'GasBuddy_Main3_300x250',
    slotID: 'div-gpt-ad-1428529431626-8',
    sizes: [[300, 250]],
  },
  { // 8
    slotName: 'GasBuddy_Main_Hero',
    slotID: 'div-gpt-ad-1532016643953-0',
    sizes: [['fluid']],
  },
  { // 9
    slotName: 'GasBuddy_Main_Alert',
    slotID: 'div-gpt-ad-1535048483352-0',
    sizes: [['fluid']],
  },
  { // 10
    slotName: 'GasBuddy_City_160x600_1',
    slotID: 'div-gpt-ad-1614026702723-0',
    sizes: [[160, 600]],
    // Only show ad on desktop devices
    sizeMapping: [
      [Dimensions.Desktop, [
        [160, 600],
      ]],
      [Dimensions.Tablet, []],
      [Dimensions.Mobile, []],
    ],
  },
  { // 11
    slotName: 'GasBuddy_City_160x600_2',
    slotID: 'div-gpt-ad-1614027004090-0',
    sizes: [[160, 600]],
    // Only show ad on desktop devices
    sizeMapping: [
      [Dimensions.Desktop, [
        [160, 600],
      ]],
      [Dimensions.Tablet, []],
      [Dimensions.Mobile, []],
    ],
  },
  { // 12
    slotName: 'GasBuddy_City_160x600_3',
    slotID: 'div-gpt-ad-1614027022836-0',
    sizes: [[160, 600]],
    // Only show ad on desktop devices
    sizeMapping: [
      [Dimensions.Desktop, [
        [160, 600],
      ]],
      [Dimensions.Tablet, []],
      [Dimensions.Mobile, []],
    ],
  },
  { // 13
    slotName: 'GasBuddy_State_160x600_1',
    slotID: 'div-gpt-ad-1614027060564-0',
    sizes: [[160, 600]],
    // Only show ad on desktop devices
    sizeMapping: [
      [Dimensions.Desktop, [
        [160, 600],
      ]],
      [Dimensions.Tablet, []],
      [Dimensions.Mobile, []],
    ],
  },
  { // 14
    slotName: 'GasBuddy_State_160x600_2',
    slotID: 'div-gpt-ad-1614027063812-0',
    sizes: [[160, 600]],
    // Only show ad on desktop devices
    sizeMapping: [
      [Dimensions.Desktop, [
        [160, 600],
      ]],
      [Dimensions.Tablet, []],
      [Dimensions.Mobile, []],
    ],
  },
  { // 15
    slotName: 'GasBuddy_State_160x600_3',
    slotID: 'div-gpt-ad-1614027066883-0',
    sizes: [[160, 600]],
    // Only show ad on desktop devices
    sizeMapping: [
      [Dimensions.Desktop, [
        [160, 600],
      ]],
      [Dimensions.Tablet, []],
      [Dimensions.Mobile, []],
    ],
  },
  { // 16
    slotName: 'GasBuddy_SDP_160x600_1',
    slotID: 'div-gpt-ad-1628872838355-0',
    sizes: [[160, 600]],
    // Only show ad on desktop devices
    sizeMapping: [
      [Dimensions.Desktop, [
        [160, 600],
      ]],
      [Dimensions.Tablet, []],
      [Dimensions.Mobile, []],
    ],
  },
  { // 17
    slotName: 'GasBuddy_SDP_160x600_2',
    slotID: 'div-gpt-ad-1628872840758-0',
    sizes: [[160, 600]],
    // Only show ad on desktop devices
    sizeMapping: [
      [Dimensions.Desktop, [
        [160, 600],
      ]],
      [Dimensions.Tablet, []],
      [Dimensions.Mobile, []],
    ],
  },
  { // 18
    slotName: 'GasBuddy_SDP_160x600_3',
    slotID: 'div-gpt-ad-1628872842995-0',
    sizes: [[160, 600]],
    // Only show ad on desktop devices
    sizeMapping: [
      [Dimensions.Desktop, [
        [160, 600],
      ]],
      [Dimensions.Tablet, []],
      [Dimensions.Mobile, []],
    ],
  },
  { // 19
    slotName: 'GasBuddy_TCC1_300x250',
    slotID: 'div-gpt-ad-1428529431626-9',
    sizes: [[300, 250]],
    // Only show ad on tablet & desktop devices
    sizeMapping: [
      [Dimensions.Tablet, [
        [300, 250],
      ]],
      [Dimensions.Mobile, []],
    ],
  },
  { // 20
    slotName: 'GasBuddy_Leader_300x250_1',
    slotID: 'div-gpt-ad-1714495428051-0',
    sizes: [[300, 250], [300, 600]],
    // Only show ad on desktop devices
    sizeMapping: [
      [Dimensions.Desktop, [
        [300, 250],
        [300, 600],
      ]],
      [Dimensions.Tablet, []],
      [Dimensions.Mobile, []],
    ],
  },
  { // 21
    slotName: 'GasBuddy_Leader_300x250_2',
    slotID: 'div-gpt-ad-1714495453226-0',
    sizes: [[300, 250], [300, 600]],
    // Only show ad on desktop devices
    sizeMapping: [
      [Dimensions.Desktop, [
        [300, 250],
        [300, 600],
      ]],
      [Dimensions.Tablet, []],
      [Dimensions.Mobile, []],
    ],
  },
  { // 22
    slotName: 'GasBuddy_Leader_300x250_3',
    slotID: 'div-gpt-ad-1714495463448-0',
    sizes: [[300, 250], [300, 600]],
    // Only show ad on desktop devices
    sizeMapping: [
      [Dimensions.Desktop, [
        [300, 250],
        [300, 600],
      ]],
      [Dimensions.Tablet, []],
      [Dimensions.Mobile, []],
    ],
  },
  { // 23
    slotName: 'GasBuddy_Leader_320x50',
    slotID: 'div-gpt-ad-1714495474738-0',
    sizes: [[320, 50]],
    // Only show ad on mobile devices
    sizeMapping: [
      [Dimensions.Tablet, []],
      [Dimensions.Mobile, [
        [320, 50],
      ]],
    ],
  },
  { // 24
    slotName: 'GasBuddy_Leader_728x90',
    slotID: 'div-gpt-ad-1714495484852-0',
    sizes: [[728, 90]],
    // Only show ad on tablet & desktop devices
    sizeMapping: [
      [Dimensions.Tablet, [
        [728, 90],
      ]],
      [Dimensions.Mobile, []],
    ],
  },
];
